<template>
  <div class="topic">
    <div v-if="current" :class="'topicMain topicMain' + current">
      <h1>关卡{{ currentData.questionId }}&nbsp;{{ currentData.name }}</h1>
      <div class="words">
        <b>{{ currentData.title }}</b>
        <p v-html="currentData.desc"></p>
      </div>
      <img
        class="imgPic"
        :src="require('../../assets/image/other/img' + current + '.png')"
        alt=""
      />
      <div class="startBtn" @click="begin">
        {{ current == '3-1' ? 'ok' : '开始游戏' }}
      </div>
    </div>
  </div>
</template>
<script>
import topicData from '@/assets/json/topic.json';
export default {
  data() {
    return {
      current: '',
      currentData: {},
    };
  },
  methods: {
    begin() {
      this.$util.fullScreen();
      if (this.current == '1-1') {
        // this.$router.replace('/card');
        this.$router.replace({
          path: '/card',
          query: {
            practice: 1,
          },
        });
      }
      if (this.current == '2-1') {
        this.$router.replace('/faceIdentify');
      }
      if (this.current == '2-2') {
        // this.$router.replace('/faceDelay');
        this.$router.replace({
          path: '/faceDelay',
          query: {
            practice: 1,
          },
        });
      }
      if (this.current == '2-3') {
        this.$router.replace('/faceMemoryTip');
      }
      if (this.current == '3-1') {
        this.$router.replace('/continuousTip');
      }
      if (this.current == '4-1') {
        this.$router.replace('/delay');
      }
      if (this.current == '5-1') {
        this.$router.replace('/selfJudgeTip');
      }
      if (this.current == '5-2') {
        this.$router.replace('/selfInterferTip');
      }
      if (this.current == '5-3') {
        this.$router.replace('/selfMemory');
      }
    },
  },
  mounted() {
    this.current = this.$util.getStorage('quesNum');
    this.currentData = topicData[this.current];
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .topic {
    text-align: center;
    width: 100%;
    min-width: 1000px;
    height: 100vh;
    background-image: url('../../assets/image/other/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'SYHeiBold';
    .topicMain {
      width: 1000px;
      height: 650px;
      position: relative;
      margin: 0 auto;
      top: calc((100% - 650px) / 2);
      h1 {
        font-size: 36px;
        color: #0f794c;
        text-align: left;
        text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;
      }
      .words {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        text-align: left;
        b {
          font-weight: normal;
          font-size: 22px;
          line-height: 30px;
        }
        p {
          font-size: 18px;
          line-height: 24px;
          white-space: pre-line;
        }
        ::v-deep span {
          color: #a81016 !important;
        }
      }
      .startBtn {
        width: 216px;
        height: 84px;
        line-height: 65px;
        text-align: center;
        display: inline-block;
        background-image: url('../../assets/image/other/btn.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 30px;
        color: #ffffff;
        position: absolute;
        left: 50%;
        bottom: 20px;
        margin-left: -108px;
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
      }
    }
    .topicMain1-1 {
      .words {
        width: 515px;
        height: 234px;
        background-image: url('../../assets/image/other/xu3.png');
        margin-top: 66px;
        padding: 35px 70px 41px 60px;
        color: #ff6d1b;
        p {
          font-size: 22px;
          line-height: 26px;
        }
      }
      .imgPic {
        width: 274px;
        height: 476px;
        position: absolute;
        top: 157px;
        right: 50px;
      }
    }
    .topicMain2-1 {
      .words {
        width: 562px;
        height: 330px;
        // height: 234px;
        background-image: url('../../assets/image/other/xu1-1.png');
        margin-top: 66px;
        padding: 45px 52px 40px 42px;
        color: #ff6d1b;
      }
      .imgPic {
        width: 247px;
        height: 479px;
        position: absolute;
        top: 146px;
        right: 52px;
      }
    }
    .topicMain2-2 {
      .words {
        width: 520px;
        height: 225px;
        // height: 190px;
        background-image: url('../../assets/image/other/xu1-2.png');
        margin-top: 105px;
        padding: 25px 50px 32px 30px;
        color: #ff6d1b;
      }
      .imgPic {
        width: 271px;
        height: 522px;
        position: absolute;
        top: 118px;
        right: 50px;
      }
    }
    .topicMain2-3 {
      .words {
        width: 545px;
        height: 245px;
        background-image: url('../../assets/image/other/xu1-3.png');
        margin-top: 75px;
        padding: 25px 61px 37px 54px;
        color: #289257;
      }
      .imgPic {
        width: 412px;
        height: 308px;
        position: absolute;
        top: 292px;
        right: 30px;
      }
    }
    .topicMain3-1 {
      .words {
        width: 540px;
        height: 270px;
        background-image: url('../../assets/image/other/xu2.png');
        margin-top: 78px;
        padding: 25px 17px 43px 48px;
        color: #289257;
      }
      .imgPic {
        width: 221px;
        height: 261px;
        position: absolute;
        top: 286px;
        right: 60px;
      }
    }
    .topicMain4-1 {
      .words {
        width: 582px;
        height: 277px;
        background-image: url('../../assets/image/other/xu4.png');
        margin-top: 107px;
        padding: 25px 62px 48px 49px;
        color: #289257;
      }
      .imgPic {
        width: 211px;
        height: 391px;
        position: absolute;
        top: 207px;
        right: 65px;
      }
    }
    .topicMain5-1 {
      .words {
        width: 606px;
        height: 278px;
        background-image: url('../../assets/image/other/xu5-1.png');
        margin-top: 71px;
        padding: 40px 27px 45px 48px;
        color: #ff6d1b;
        margin-left: 145px;
      }
      .imgPic {
        width: 288px;
        height: 442px;
        position: absolute;
        top: 177px;
        right: 25px;
      }
    }
    .topicMain5-2 {
      .words {
        width: 666px;
        height: 262px;
        background-image: url('../../assets/image/other/xu5-2.png');
        margin-top: 60px;
        padding: 45px 64px 45px 73px;
        color: #289257;
        p {
          line-height: 30px;
        }
      }
      .imgPic {
        width: 192px;
        height: 432px;
        position: absolute;
        top: 180px;
        right: 55px;
      }
    }
    .topicMain5-3 {
      .words {
        width: 666px;
        height: 302px;
        background-image: url('../../assets/image/other/xu5-3.png');
        margin-top: 78px;
        padding: 46px 52px;
        color: #ff6d1b;
        // margin: 177px 0 0 100px;
        p {
          line-height: 30px;
        }
      }
      .imgPic {
        width: 240px;
        height: 253px;
        position: absolute;
        top: 320px;
        right: 65px;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .topic {
    text-align: center;
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    background-image: url('../../assets/image/other/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'SYHeiBold';
    .topicMain {
      width: 1300px;
      height: 845px;
      position: relative;
      margin: 0 auto;
      top: calc((100% - 845px) / 2);
      h1 {
        font-size: 47px;
        color: #0f794c;
        text-align: left;
        text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;
      }
      .words {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        text-align: left;
        b {
          font-weight: normal;
          font-size: 29px;
          line-height: 39px;
        }
        p {
          font-size: 23px;
          line-height: 31px;
          white-space: pre-line;
        }
        ::v-deep span {
          color: #a81016 !important;
        }
      }
      .startBtn {
        width: 281px;
        height: 109px;
        line-height: 85px;
        text-align: center;
        display: inline-block;
        background-image: url('../../assets/image/other/btn.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 39px;
        color: #ffffff;
        position: absolute;
        left: 50%;
        bottom: 26px;
        margin-left: -140px;
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
      }
    }
    .topicMain1-1 {
      .words {
        width: 670px;
        height: 304px;
        background-image: url('../../assets/image/other/xu3.png');
        margin-top: 86px;
        padding: 46px 91px 54px 78px;
        color: #ff6d1b;
        p {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .imgPic {
        width: 356px;
        height: 619px;
        position: absolute;
        top: 204px;
        right: 65px;
      }
    }
    .topicMain2-1 {
      .words {
        width: 731px;
        height: 429px;
        // height: 234px;
        background-image: url('../../assets/image/other/xu1-1.png');
        margin-top: 86px;
        padding: 59px 68px 52px 55px;
        color: #ff6d1b;
      }
      .imgPic {
        width: 321px;
        height: 623px;
        position: absolute;
        top: 190px;
        right: 68px;
      }
    }
    .topicMain2-2 {
      .words {
        width: 676px;
        height: 293px;
        // height: 190px;
        background-image: url('../../assets/image/other/xu1-2.png');
        margin-top: 137px;
        padding: 32px 65px 41px 39px;
        color: #ff6d1b;
      }
      .imgPic {
        width: 352px;
        height: 679px;
        position: absolute;
        top: 153px;
        right: 65px;
      }
    }
    .topicMain2-3 {
      .words {
        width: 709px;
        height: 318px;
        background-image: url('../../assets/image/other/xu1-3.png');
        margin-top: 97px;
        padding: 33px 79px 48px 70px;
        color: #289257;
      }
      .imgPic {
        width: 536px;
        height: 400px;
        position: absolute;
        top: 380px;
        right: 39px;
      }
    }
    .topicMain3-1 {
      .words {
        width: 702px;
        height: 351px;
        background-image: url('../../assets/image/other/xu2.png');
        margin-top: 101px;
        padding: 33px 22px 56px 63px;
        color: #289257;
      }
      .imgPic {
        width: 288px;
        height: 351px;
        position: absolute;
        top: 372px;
        right: 78px;
      }
    }
    .topicMain4-1 {
      .words {
        width: 757px;
        height: 360px;
        background-image: url('../../assets/image/other/xu4.png');
        margin-top: 139px;
        padding: 33px 81px 63px 63px;
        color: #289257;
      }
      .imgPic {
        width: 275px;
        height: 508px;
        position: absolute;
        top: 270px;
        right: 85px;
      }
    }
    .topicMain5-1 {
      .words {
        width: 789px;
        height: 362px;
        background-image: url('../../assets/image/other/xu5-1.png');
        margin-top: 93px;
        padding: 52px 35px 59px 63px;
        color: #ff6d1b;
        margin-left: 189px;
      }
      .imgPic {
        width: 375px;
        height: 549px;
        position: absolute;
        top: 230px;
        right: 33px;
      }
    }
    .topicMain5-2 {
      .words {
        width: 866px;
        height: 341px;
        background-image: url('../../assets/image/other/xu5-2.png');
        margin-top: 78px;
        padding: 59px 83px 59px 95px;
        color: #289257;
        p {
          line-height: 39px;
        }
      }
      .imgPic {
        width: 250px;
        height: 562px;
        position: absolute;
        top: 234px;
        right: 72px;
      }
    }
    .topicMain5-3 {
      .words {
        width: 866px;
        height: 393px;
        background-image: url('../../assets/image/other/xu5-3.png');
        margin-top: 102px;
        padding: 60px 68px;
        color: #ff6d1b;
        // margin: 177px 0 0 100px;
        p {
          line-height: 39px;
        }
      }
      .imgPic {
        width: 312px;
        height: 329px;
        position: absolute;
        top: 416px;
        right: 85px;
      }
    }
  }
}
</style>
